//登录、注册、找回密码配置
// import axios from "axios";
// import {validateEMail, validateEmpty, validateName, validatePassword} from "@/utils/validate";
// import {messageTips} from "@/utils/messageTip";
// import qs from 'qs';

export default {
    namespaced: true,//不加命名空间，别的组件拿不到state中的数据
    actions: {},
    mutations: {},
    state: {
    },
    getters: {},
}