import { render, staticRenderFns } from "./SubmissionMain.vue?vue&type=template&id=123ae44e&scoped=true&"
import script from "./SubmissionMain.vue?vue&type=script&lang=js&"
export * from "./SubmissionMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123ae44e",
  null
  
)

export default component.exports