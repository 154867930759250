<template>
  <div class="juzhong">
    <h1>404 - Page Not Found</h1>
    <hr/>
  </div>
</template>

<script>
export default {
  name: "NotFound",
}

</script>
<style scoped>
.juzhong {
  width: 100%;
  vertical-align: center;
  margin: 0 auto; /* 水平居中 */
}
</style>