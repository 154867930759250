<template>
  <div>
    <div style="height: 30px;"></div>
    <table style="border: #bcbec2 solid 1px;" class="table">
      <tr class="th">
        <td style="width: 10%;">#</td>
        <td style="width: 12%;">提交者</td>
        <td style="width: 17%;">题目</td>
        <td style="width: 6%;">语言</td>
        <td style="width: 15%;">评测结果</td>
        <td style="width: 8%;">耗时</td>
        <td style="width: 8%;">内存</td>
        <td style="width: 19%;">提交时间</td>
      </tr>

      <tr v-for="sl in submissionList" :key="sl.id">
        <td>
          <el-link :underline="false" class="a-color" @click="toSubmissionDetail(sl.id,sl.user_id)">{{
              sl.id
            }}
          </el-link>
        </td>
        <td>{{ sl.userName }}</td>
        <td>
          <el-link :underline="false" class="a-color">{{ sl.problem_id }}.{{ sl.problemTitle }}</el-link>
        </td>
        <td>{{ sl.languageName }}</td>
        <td :class="classArr[sl.status]">{{ sl.statusName }}</td>
        <td>{{ sl.exec_time==null?'NaN':sl.exec_time }}</td>
        <td>{{ (sl.consume_memory/1024).toFixed(3) }}</td>
        <td>{{ sl.create_time |timer }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import {messageTips} from "@/utils/messageTip";

export default {
  name: "ProblemSubmission",
  data() {
    return {
      submissionList: [],
      classArr: ["", "green", "yellow", "red", "yellow", "grey", "orange", "orange", "orange", "blue"],
    }
  },
  methods: {
    //获取提交记录list
    searchSubmissionList() {
      // this.$store.state.fullscreenLoading = true;
      axios({
        url: "/submission/searchSubmissionList",
        method: "post",
        data: qs.stringify({
          problem_id: this.$route.params.id,
          user_id: localStorage.getItem("id"),
        })
      }).then(response => {
            if (response.data) {
              //成功拿到数据后修改本地数据
              this.submissionList = response.data;
            } else {
              messageTips(this, '啊哦，请求提交记录失败!', "warning");
            }
          },
          error => {
            console.log("searchSubmissionListByMap 请求失败", error);
            messageTips(this, '啊哦，网络打了个盹', "error");
          })
    },
    // 转去提交详情界面
    toSubmissionDetail(id, user_id) {
      if (user_id == localStorage.getItem("id"))
        this.$router.push({
          name: 'submissionDetail',
          params: {
            id: id,
          },
        })
      else
        messageTips(this, '访问受限', "warning");
    },
  },
  created() {
    this.$store.state.fullscreenLoading = true;
    this.searchSubmissionList();
  },
  mounted() {
    setTimeout(() => {
      this.$store.state.fullscreenLoading = false;
    }, 1000);
  }
}
</script>

<style scoped>
table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;

}

tr {
  height: 35px;
  font-size: 14px;
}

td {
  border: 1px solid rgba(34, 36, 38, .1);
  margin: 0;
  padding: 0;
}

.a-color {
  color: #4183C4;
}

.th {
  background-color: rgba(211, 183, 216, 0.58);
  font-size: 15px;
}

.green {
  color: #21BA45;
}

.red {
  color: #DB2828;
}

.yellow {
  color: #FBBD08;
}

.grey {
  color: #767676;
}

.orange {
  color: #F2711C;
}

.blue {
  color: rgb(33, 133, 208);
}
</style>