<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "SubmissionMain",
  data() {
    return {}
  },
  methods: {},
  created() {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>